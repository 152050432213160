import React from "react"
// import PropTypes from 'prop-types'

import Layout from '../components/layout'

// Components
import { Link, graphql } from "gatsby"

const Contributors = ({ pageContext, data, location }) => {
  // if (contributors == null || contributors.length < 1) {
  //  return null;
  // } 
  const siteTitle = data.site.siteMetadata.title
  const { edges, totalCount } = data.allMdx
  const authorHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } por "${pageContext.id}"`

  return (
    <Layout location={location} title={siteTitle}>
      <div>
        <h1>{authorHeader}</h1>
        <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug}>
                <Link to={"blog/" + slug}>{title}</Link>
              </li>
            )
          })}
        </ul>
        {/*
                This links to a page that does not yet exist.
                You'll come back to it!
              */}
        <Link to="/contributors">Voltar</Link>
      </div>
    </Layout>
  );
 };

export default Contributors

export const pageQuery = graphql`
  query($id: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allContributorYaml(
        filter: { id: { eq: "jane_doe" } }
      ) {
        edges {
          node {
            id
            name
            bio
          }
        }
      }
      allMdx(
        filter: {
          frontmatter: { contributor: { in: [$id] } } 
        }
        ) {
          totalCount
          edges {
            node {
              id
          frontmatter {
            title
            contributor
          }
          fields {
            slug
          }
        }
      }
    }
  }
`